import React, { useContext, useReducer } from "react";

interface RecordingState {
  confirm: boolean;
  enabledMic: boolean;
  enabledInternalAudio: boolean;
  locale: string;
  translateLocale: string | undefined;
  status: "idle" | "recording" | "stopped";
  noteId?: string;
  startTimestamp?: Date;
}
type ActionProps =
  | {
      type: "START";
      payload: {
        enabledMic: boolean;
        enabledInternalAudio: boolean;
        locale: string;
        translateLocale: string | undefined;
      };
    }
  | { type: "RESET" }
  | {
      type: "START_RECORDING";
      payload: {
        noteId: string;
        timestamp: Date;
      };
    }
  | {
      type: "STOP_RECORDING";
    };

const reducer: React.Reducer<RecordingState, ActionProps> = (state, action): RecordingState => {
  switch (action.type) {
    case "RESET": {
      return initialState;
    }
    case "START": {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        confirm: true,
      };
    }
    case "START_RECORDING": {
      const { payload } = action;
      return {
        ...state,
        status: "recording",
        noteId: payload.noteId,
        startTimestamp: payload.timestamp,
      };
    }
    case "STOP_RECORDING": {
      return {
        ...state,
        status: "stopped",
      };
    }
    default: {
      console.error("Unknown action:", action);
      return state;
    }
  }
};

const initialState: RecordingState = {
  confirm: false,
  enabledMic: true,
  enabledInternalAudio: false,
  status: "idle",
  locale: "ja-JP",
  translateLocale: undefined,
};

export const RecordingStateContext = React.createContext<RecordingState>(initialState);

export const RecordingDispatchContext = React.createContext<React.Dispatch<ActionProps>>((() => {
  // noop
}) as React.Dispatch<ActionProps>);

export const RecordingProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <RecordingStateContext.Provider value={state}>
      <RecordingDispatchContext.Provider value={dispatch}>{children}</RecordingDispatchContext.Provider>
    </RecordingStateContext.Provider>
  );
};

export function useRecordingStateContext() {
  return useContext(RecordingStateContext);
}

export function useRecordingStateDispatch() {
  return useContext(RecordingDispatchContext);
}
